export default {
    user: {
        me: '/api/user/me/',
        promo: '/api/info_module/',
        changeMe: '/api/user/change_me/',
        create: '/api/user/create_company/',
        changeContact: '/api/user/change_contact/',
        setContact: '/api/user/set_contact/',
        resetPassword: '/api/user/reset_password/',
        switch: '/api/user/switch/',
        specs: '/api/user/spec/',
        changeSearchStatus: '/api/user/change_search_status/',
        companies: '/api/user/votes_expert/',

    },
};
