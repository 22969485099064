export default {
    company: {
        about: '/api/user_company/about/',
        contacts: '/api/user_company/contact/',
        change: '/api/user_company/change_about/',
        changeContacts: '/api/user_company/change_contact/',
        switch: '/api/user_company/switch/',
        specializations: '/api/companies_specialization/',
        countries: '/api/countries/',
        cities: (country = []) => `/api/cities/?country=${country}`,
        clients: '/api/companies_client/',
        verify: '/api/user_company/change_verification/',
    },
};
