import Vue from 'vue';
import Swiper from 'swiper';

import {
    Autoplay,
    FreeMode,
    EffectFade,
    Pagination,
    Grid,
    // Navigation,
    // Scrollbar,
    // Thumbs,
    // Mousewheel,
} from 'swiper/modules';

const swiper = {
    install(Vue, options) {
        Vue.prototype.$Swiper = Swiper;
        Vue.prototype.$SwiperModules = {
            Autoplay,
            FreeMode,
            EffectFade,
            Pagination,
            Grid,
            // Navigation,
            // Scrollbar,
            // Thumbs,
            // Mousewheel,
        };
    },
};

Vue.use(swiper);
