export default {
    auth: {
        login: '/api/auth/login/',
        logout: '/api/auth/logout/',
        register: '/api/auth/register/',
        otp: '/api/otp_token/',
        validateOtp: '/api/otp_token/validate_otp_token/',
        recoverPassword: '/api/auth/recovery_password/',
        validateMagicLink: '/api/magic_link/validate_magic_link/',
        addVkLogin: '/api/socials/vk/login/',
        removeVkLogin: '/api/socials/vk/remove/',
        addGoogleLogin: '/api/socials/google/login/',
        removeGoogleLogin: '/api/socials/google/remove/',
    },
};
