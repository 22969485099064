export default {
    requestsVacancy: {
        list: '/api/vacancies/me/requests/',
        specs: '/api/vacancies/me/requests/specs/',
        facets: '/api/vacancies/me/requests/facets/',
        answer: '/api/vacancies/me/requests/answer/',
    },

    requestsResume: {
        list: '/api/resumes/me/requests/',
        specs: '/api/resumes/me/requests/specs/',
        facets: '/api/resumes/me/requests/facets/',
    },
};
