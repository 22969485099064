export default {
    media: {
        list: '/api/media/',
        latest: '/api/media/latest/',
        company: '/api/media/companies/',
        youtube: '/api/media/youtube/',
        specs: '/api/media/specs/',
        facets: '/api/media/facets/',
        detail: (id, slug) => `/api/media/${id}/${slug}`,
        moreArticles: (id, slug, offset = 1) => `/api/media/${id}/${slug}/infinite_media/`,
        similar: (id, slug) => `/api/media/${id}/${slug}/similar/`,
        video: '/api/video/',
        editionContacts: '/api/edition/contacts/',
        category: '/api/tags/category/',
        data: '/api/page_content/media/',
        banner: '/api/tags/',
    },
};
