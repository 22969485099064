/* Скрипты, которые можно подключить нужно на определенных страницах */
const otherScripts = {
    /* JIVO */
    jivo: {
        src: '//code.jivo.ru/widget/yHDJcASRTn',
        async: true,
    },

    YandexCaptcha: {
        src: 'https://smartcaptcha.yandexcloud.net/captcha.js?render=onload&onload=onloadFunction',
        defer: true,
    },

    /* Google Search */
    search: {
        src: 'https://cse.google.com/cse.js?cx=656c1ab0511274a32&hl=ru',
        async: true,
    },
};

export { otherScripts };
