export default {
    rating: {
        data: '/api/rating/',
        experts: '/api/rating/expert/',
        request: '/api/request/company_rating/',
        vote: '/api/rating/vote/',
        methodology: '/api/methodology-page/',
        expertVote: '/api/rating/vote_expert/',
        expertResults: '/api/rating/vote_expert/bulk_create/',
    },
};
