export default {
    companies: {
        list: '/api/companies/',
        specs: '/api/companies/specs/',
        facets: '/api/companies/facets/',
        rating: '/api/companies/rating/',
        mainPage: '/api/companies/main_page/',
        slug: slug => `/api/companies/${slug}/`,
        info: id => `/api/companies/${id}/info/`,
        documents: id => `/api/companies_documents/?company=${id}`,
        job: slug => `/api/companies/${slug}/job/`,
        employers: '/api/companies/employer/',
    },
};
