import companies from '~/config/api/companies';
import media from '~/config/api/media';
import job from '~/config/api/job';
import video from '~/config/api/video';
import school from '~/config/api/school';
import main from 'portal/config/api/main';
import advertiser from 'portal/config/api/advertiser';
import projects from 'portal/config/api/projects';
import documents from 'portal/config/api/documents';
import settings from 'portal/config/api/settings';
import contacts from 'portal/config/api/contacts';
import auth from 'portal/config/api/auth';
import user from 'portal/config/api/user';
import company from 'portal/config/api/company';
import rating from 'portal/config/api/rating';
import resumes from 'portal/config/api/resumes';
import vacancies from 'portal/config/api/vacancies';
import requests from 'portal/config/api/requests';

const apiConfig = {
    ...companies,
    ...media,
    ...video,
    ...job,
    ...school,
    ...main,
    ...advertiser,
    ...projects,
    ...documents,
    ...settings,
    ...contacts,
    ...auth,
    ...user,
    ...company,
    ...rating,
    ...resumes,
    ...vacancies,
    ...requests,
};

export default apiConfig;
